import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
	updateBusinesses,
	updateBusiness,
	updateIntegration,
	updateIntegrations,
	updateProfile,
	updateStatus,
	updateWallet,
	logout,
} from '../services/businessSlice';

import {
	updateWhiteLabelling,
	updateWhiteLabellingBusinessLogo,
	updateWhiteLabellingButtonShape,
	updateWhiteLabellingPrimaryColor,
	updateWhiteLabellingSecondaryColor,
	updateWhiteLabellingType,
} from '../services/whitelabelling';

export const useActionCreator = () => {
	const dispatch = useDispatch();

	return bindActionCreators(
		{
			updateBusinesses,
			updateBusiness,
			updateIntegration,
			updateIntegrations,
			updateProfile,
			updateStatus,
			updateWallet,
			logout,
			updateWhiteLabelling,
			updateWhiteLabellingBusinessLogo,
			updateWhiteLabellingButtonShape,
			updateWhiteLabellingPrimaryColor,
			updateWhiteLabellingSecondaryColor,
			updateWhiteLabellingType,
		},
		dispatch
	);
};
