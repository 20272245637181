import { ReactComponent as Dashboard } from '../../../svgs/dashboard.svg';
import { ReactComponent as Transaction } from '../../../svgs/transactions.svg';
import { ReactComponent as SubAccount } from '../../../svgs/sub-account.svg';
import { ReactComponent as DigitalWallet } from '../../../svgs/digital-wallet.svg';
import { ReactComponent as Payment } from '../../../svgs/payment.svg';
import { ReactComponent as Payouts } from '../../../svgs/payout.svg';
import { ReactComponent as Mandate } from '../../../svgs/mandate.svg';
import { ReactComponent as Profiles } from '../../../svgs/profiles.svg';
import { ReactComponent as SwitchBiz } from '../../../svgs/switch-biz.svg';
import { ReactComponent as Settings } from '../../../svgs/settings.svg';
import { ReactComponent as FileCopy } from '../../../svgs/file_copy.svg';
import { ReactComponent as LiveHelp } from '../../../svgs/live_help.svg';
import { StyledNavabar, StyledWrapper, StyledLogo, StyledLink, StyledLogOut, LogoutButton } from './styledSideBar';
import Button from '../../../custom/button';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useActionCreator } from '../../../hooks/useActionCreator';
import { useHistory } from 'react-router-dom';

const SideBar: React.FC = () => {
	const business = useSelector((state: RootState) => state?.business?.businesses);
	const { logout } = useActionCreator();
	const navigate = useHistory();

	const isGetStarted = (): boolean => {
		let status = false;
		//eslint-disable-next-line
		if (business?.status == 'Live' || business?.status == 'AwaitingApproval' || business?.status == 'Deactivated')
			status = true;
		return status;
	};

	const handleLogout = () => {
		logout();
		navigate.push('/');
	};

	return (
		<StyledNavabar>
			<StyledWrapper>
				<StyledLogo />
				<StyledLink to='/dashboard' activeStyle={styles}>
					<Dashboard /> <span>Dashboard</span>
				</StyledLink>
				{!isGetStarted() && (
					<StyledLink to='/createAccount' activeStyle={styles}>
						<Dashboard /> <span>Get Started</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/transaction' activeStyle={styles}>
						<Transaction /> <span>Transaction</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/sub-account' activeStyle={styles}>
						<SubAccount /> <span>Sub Account</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/digital-wallet' activeStyle={styles}>
						<DigitalWallet /> <span>Digital Wallet</span>
					</StyledLink>
				)}

				{isGetStarted() && (
					<StyledLink to='/customer-wallet' activeStyle={styles}>
						<DigitalWallet /> <span>Customer Wallet</span>
					</StyledLink>
				)}

				{isGetStarted() && (
					<StyledLink to='/payment-link' activeStyle={styles}>
						<Payment /> <span>Payment Link</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/payouts' activeStyle={styles}>
						<Payouts /> <span>Payouts</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/mandates' activeStyle={styles}>
						<Mandate />
						<span>Mandates</span>
					</StyledLink>
				)}
				{isGetStarted() && (
					<StyledLink to='/switch-business' activeStyle={styles}>
						<SwitchBiz />
						<span>Switch Biz</span>
					</StyledLink>
				)}
				<StyledLink to='/business-profile' activeStyle={styles}>
					<Profiles />
					<span>Profiles</span>
				</StyledLink>

				{/* {isGetStarted() && (
					<StyledLink to='/white-labelling' activeStyle={styles}>
						<Dashboard />
						<span>White Labelling</span>
					</StyledLink>
				)} */}
				<StyledLink to='/settings' activeStyle={styles}>
					<Settings />
					<span>Settings</span>
				</StyledLink>
				<LogoutButton onClick={handleLogout}>
					<StyledLogOut />
					<span>Logout</span>
				</LogoutButton>
			</StyledWrapper>

			<Button text='Documentation' marginBottom={'2rem'} icon={<FileCopy />} />
			<Button text='Help & FAQ' icon={<LiveHelp />} />
		</StyledNavabar>
	);
};

const styles = {
	color: '#2F8ED6',
	backgroundColor: '#f4f4f4',
	borderRadius: '8px',
	'& $*': {
		fill: '#2F8ED6',
	},
};

export default SideBar;
