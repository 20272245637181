import { combineReducers } from '@reduxjs/toolkit';
import businessReducer from '../../services/businessSlice';
import whiteLabellingReducer from '../../services/whitelabelling';

export const rootReducer = combineReducers({
	business: businessReducer,
	whiteLabelling: whiteLabellingReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;
