import { lazy, Suspense } from 'react';
import Fallback from '../custom/fallback';
import DashboardLayout from './dashboardLayout/dashboardLayout';

const Main = lazy(() => import('../components/whitelabelling/customColor/customColor'));

const WhiteLabellingButton = () => {
	return (
		<DashboardLayout heading='CyberPay Merchant / White Labelling'>
			<Suspense fallback={<Fallback />}>
				<Main />
			</Suspense>
		</DashboardLayout>
	);
};

export default WhiteLabellingButton;
