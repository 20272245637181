import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProtectRoute from './components/auth/ProtectedRoute';
import Transaction from './screens/transaction';
import Dashboard from './screens/Dashboard';
import SubAccount from './screens/subAccount';
import DigitalWallet from './screens/digitalWallet';
import Products from './screens/products';
import Payouts from './screens/payOut';
import PaymentLink from './screens/PaymentLink';
import Mandates from './screens/mandates';
import CreateAccount from './screens/createAccount';
import TestIntegration from './screens/testIntegration';
import UpdateProfile from './screens/updateProfile';
import UpdateDocument from './screens/updateDocument';
import BusinessProfile from './screens/businessProfile';
import Settings from './screens/settings';
import SwitchBusiness from './screens/switchBusiness';
import SignIn from './screens/signIn';
import ResetPassword from './screens/resetPassword';
import SignUp from './screens/signUp';
import ResendPasswordLink from './screens/resendPasswordLink';
import CreateNewPassword from './screens/createNewPassword';
import VerifyEmail from './screens/verifyEmail';
import VerifySuccess from './screens/verifySuccess';
import OtpVerification from './screens/otpVerification';
import AppGuide from './screens/appGuide';
import CustomerWallet from './screens/customerWallet';
import WhiteLabeling from './screens/whiteLabeling';
import CustomLogo from './screens/customLogo';
import CustomColor from './screens/customColor';
import Button from './screens/button';

function App() {
	return (
		<Router>
			<Switch>
				<Route path='/signUp' exact component={SignUp} />
				<ProtectRoute path='/createAccount' exact component={CreateAccount} />
				<Route path='/reset-password' exact component={ResetPassword} />
				<Route path='/reset-password/:username' component={ResendPasswordLink} />
				<Route path='/create-new-password' exact component={CreateNewPassword} />
				<Route path='/verify-email' exact component={VerifyEmail} />
				<Route path='/verify-success' component={VerifySuccess} />
				<Route path='/' exact component={SignIn} />
				<Route path='/app-guide' exact component={AppGuide} />
				<Route path='/otp-verification' exact component={OtpVerification} />
				<ProtectRoute path='/test-integration' exact component={TestIntegration} />
				<ProtectRoute path='/update-profile' exact component={UpdateProfile} />
				<ProtectRoute path='/update-document' exact component={UpdateDocument} />
				<ProtectRoute path='/dashboard' exact component={Dashboard} />
				<ProtectRoute path='/transaction' exact component={Transaction} />
				<ProtectRoute path='/sub-account' exact component={SubAccount} />
				<ProtectRoute path='/digital-wallet' exact component={DigitalWallet} />
				<ProtectRoute path='/customer-wallet' exact component={CustomerWallet} />
				<ProtectRoute path='/products' exact component={Products} />
				<ProtectRoute path='/payment-link' exact component={PaymentLink} />
				<ProtectRoute path='/mandates' exact component={Mandates} />
				<ProtectRoute path='/business-profile' exact component={BusinessProfile} />
				<ProtectRoute path='/settings' exact component={Settings} />
				<ProtectRoute path='/white-labelling' exact component={WhiteLabeling} />
				<ProtectRoute path='/white-labelling-custom-logo' exact component={CustomLogo} />
				<ProtectRoute path='/white-labelling-custom-color' exact component={CustomColor} />
				<ProtectRoute path='/white-labelling-custom-button' exact component={Button} />
				<ProtectRoute path='/switch-business' exact component={SwitchBusiness} />
				<ProtectRoute path='/payouts' exact component={Payouts} />
			</Switch>
		</Router>
	);
}

export default App;
