import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface WhiteLabelling {
	currentTheme: {
		businessLogo: string;
		primaryColor: string;
		secondaryColor: string;
		buttonShape: string;
		buttonType: string;
	};
	previousTheme: {
		businessLogo: string;
		primaryColor: string;
		secondaryColor: string;
		buttonShape: string;
		buttonType: string;
	};
}

const initialState: WhiteLabelling = {
	currentTheme: {
		businessLogo: 'some logo',
		primaryColor: '206AA2',
		secondaryColor: 'E1EFF9',
		buttonShape: 'square',
		buttonType: 'filled',
	},

	previousTheme: {
		businessLogo: '',
		primaryColor: '206AA2',
		secondaryColor: 'E1EFF9',
		buttonShape: 'square',
		buttonType: 'filled',
	},
};

export const whiteLabellingSlice = createSlice({
	name: 'whitelabelling',
	initialState,
	reducers: {
		updateWhiteLabelling: (state: WhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.previousTheme.businessLogo = action.payload.previousTheme.businessLogo;
			state.previousTheme.primaryColor = action.payload.previousTheme.primaryColor;
			state.previousTheme.secondaryColor = action.payload.previousTheme.secondaryColor;
			state.previousTheme.buttonShape = action.payload.previousTheme.buttonShape;
			state.previousTheme.buttonType = action.payload.previousTheme.buttonType;

			state.currentTheme.businessLogo = action.payload.currentTheme.businessLogo;
			state.currentTheme.primaryColor = action.payload.currentTheme.primaryColor;
			state.currentTheme.secondaryColor = action.payload.currentTheme.secondaryColor;
			state.currentTheme.buttonShape = action.payload.currentTheme.buttonShape;
			state.currentTheme.buttonType = action.payload.currentTheme.buttonType;
		},
		updateWhiteLabellingBusinessLogo: (state: WhiteLabelling, action: PayloadAction<{ businessLogo: string }>) => {
			state.currentTheme.businessLogo = action.payload.businessLogo;
		},
		updateWhiteLabellingPrimaryColor: (state: WhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.currentTheme.primaryColor = action.payload.currentTheme.businessLogo;
		},
		updateWhiteLabellingSecondaryColor: (state: WhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.currentTheme.secondaryColor = action.payload.currentTheme.secondaryColor;
		},
		updateWhiteLabellingButtonShape: (state: WhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.currentTheme.buttonShape = action.payload.currentTheme.buttonShape;
		},
		updateWhiteLabellingType: (state: WhiteLabelling, action: PayloadAction<WhiteLabelling>) => {
			state.currentTheme.buttonType = action.payload.currentTheme.buttonType;
		},
	},
});

export const {
	updateWhiteLabelling,
	updateWhiteLabellingBusinessLogo,
	updateWhiteLabellingButtonShape,
	updateWhiteLabellingPrimaryColor,
	updateWhiteLabellingSecondaryColor,
	updateWhiteLabellingType,
} = whiteLabellingSlice.actions;

export default whiteLabellingSlice.reducer;
